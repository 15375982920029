<template>
  <v-dialog v-model="isShown" max-width="510">
    <v-card data-cy="connectIdAustriaDialog">
      <v-card-title class="text-h5">
        {{ $t('dialog.connect_id_austria.title') }}
      </v-card-title>
      <v-card-text>
        {{ $t('dialog.connect_id_austria.information') }}
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn size="x-large" color="info" variant="outlined" @click="isShown = false">
          {{ $t('global.no_thanks') }}
        </v-btn>
        <v-spacer />
        <v-btn size="x-large" color="info" data-cy="connectIdAustriaBtn" variant="elevated" @click="connectIdAustria">
          {{ $t('global.connect') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    modelValue: Boolean,
    isDirectSign: Boolean,
  },
  setup(props) {
    const route = useRoute()

    const { t } = useI18n()

    const { createSnackbar } = useWidgetStore()

    const { userRepository, directUserRepository } = useApi()

    const isLoading = ref(false)

    const connectIdAustria = async () => {
      isLoading.value = true

      try {
        // FIXME: Careful, the endpoints for direct signers and regular user do not behave consistently
        if (props.isDirectSign) {
          await directUserRepository.update({
            tsp_preference_qes_eidas: 'atrust',
          })
        } else {
          await userRepository.updateAttributes({
            tsp_preference_qes_eidas: 'atrust',
          })
        }

        await navigateTo({
          replace: true,
          name: props.isDirectSign
            ? 'direct-sign-identification-id-austria-success'
            : 'profile-signature-standards-id-austria-success',
          query: { ...route.query },
        })
      } catch {
        createSnackbar({
          message: t('global.general_error'),
        })
      }

      isLoading.value = false
    }

    return {
      createSnackbar,
      connectIdAustria,
    }
  },
  computed: {
    isShown: {
      get(): boolean {
        return this.modelValue
      },
      set(newValue: boolean): void {
        this.$emit('update:modelValue', newValue)
      },
    },
  },
})
</script>
